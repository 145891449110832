import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Button } from "reactstrap";
import TeamCard from "../team_card/team_card";
import { Link, useLocation } from "react-router-dom";
import { GET_LEAGUE_TEAMS } from "helpers/jiraya_url";
import { getLeagueTeams } from "helpers/jiraya_helper";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useSWR from "swr";
import Loader from "components/Loader";
import { useShareModal } from "components/Modal/ShareModal";
import ShareModal from "components/Modal/ShareModal";
// import useSWR from "swr";

const STORAGE_HOST = process.env.REACT_APP_STORAGE_HOST;

const ListTeam = () => {
  const { eventId } = useParams();
  const location = useLocation();
  const shareModalProps = useShareModal();

  const { data: teams, error } = useSWR([GET_LEAGUE_TEAMS, eventId], () =>
    getLeagueTeams({ params: { league_id: eventId } })
  );

  const isLoading = !(teams || error);
  if (error) return <div>Failed to load teams</div>;
  if (isLoading)
    return (
      <div className="ht-100">
        <Loader />
      </div>
    );

  return (
    <>
      {/* Share button at the top */}
      {/* <div className="d-flex justify-content-end my-3 mx-3">
        <button
          onClick={shareModalProps.toggleModal}
          className="btn btn-success btn-rounded-2 px-3"
        >
          <i className="mdi mdi-share-variant" /> Share
        </button>
      </div>
      <ShareModal {...shareModalProps} /> */}
      <Row className="g-4 my-4 mx-2 mx-md-5 gx-5">
        {teams?.data?.teams.map((team) => (
          <Col key={team.id} xs={12} sm={6} lg={3} xl={3}>
            <Link
              to={`${location.pathname}/${team.id}${
                location.search
              }&team-name=${encodeURI(team.name)}`}
              className="text-decoration-none"
            >
              <TeamCard
                image={STORAGE_HOST + team.image_path}
                name={team.name}
                description={team.description}
              />
            </Link>
          </Col>
        ))}
      </Row>
    </>
  );
};

ListTeam.propTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ListTeam;
