import React, { useEffect, useState } from "react";
import GalleryPage2 from "pages/Public/GalleryPage2";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { getClipQueryCollectionData } from "helpers/jiraya_helper";
import { GET_CLIP_QUERY_COLLECTION_DATA } from "helpers/jiraya_url";
import useSWR from "swr";

const SponsorIq = () => {
  const params = useParams();
  const [eventId, setEventId] = useState(params.eventId);
  const [queryData, setQueryData] = useState([]);

  const queryParams = new URLSearchParams(useLocation().search);
  const queryCollectionId = +queryParams.get("query_collection_id");
  const queryCollectionKey = queryParams.get("query_collection_key");

  const { data, error } = useSWR(GET_CLIP_QUERY_COLLECTION_DATA, () =>
    getClipQueryCollectionData({
      params: {
        event_id: eventId,
        query_collection_id: queryCollectionId,
        query_collection_key: queryCollectionKey,
      },
    })
  );

  useEffect(() => {
    setQueryData(data?.data?.queries);
  }, [data]);

  return (
    <GalleryPage2
      queryData={queryData}
      queryCollectionId={queryCollectionId}
      queryCollectionKey={queryCollectionKey}
    />
  );
};

export default SponsorIq;
