import ClientGallery from "components/Events/Client/Gallery/gallery4";
import { getActivityKey } from "components/Events/utils";
import ShareModal from "components/Modal/ShareModal";
import { useShareModal } from "components/Modal/ShareModal";
import { postOpenActivity } from "helpers/jiraya_helper";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { GalleryProvider } from "store/galleryContext";

const TeamPlayer = (props) => {
  const galleryConfig = props;
  const { eventId, teamId, playerId } = useParams();
  const { search } = useLocation();
  const searchParam = new URLSearchParams(search);
  let allPlayers;
  if (playerId === "-1") {
    allPlayers = searchParam.get("all-players");
  }
  const handleActivity = async (type, image_id, sponsor_id) => {
    const unique_key = getActivityKey(
      type,
      eventId,
      image_id,
      null,
      sponsor_id,
      playerId
    );
    const visit = JSON.parse(localStorage.getItem(unique_key));
    if (!visit) {
      localStorage.setItem(unique_key, "true");
      await postOpenActivity({
        event_id: eventId,
        activity_type: type,
        image_id,
        request_id: playerId,
        sponsor_id: sponsor_id,
      });
    }
  };

  const handleTrigger = () => {
    console.log("hashtracks not implemented for teams");
  };
  return (
    <>
      <GalleryProvider value={{ handleActivity, handleTrigger }}>
        <ClientGallery
          {...galleryConfig}
          playerGalleryProps={{
            teamId,
            playerId: playerId !== "-1" ? playerId : null,
            playerIdList: allPlayers,
          }}
        />
      </GalleryProvider>
    </>
  );
};

export default TeamPlayer;
