import React, { useContext, useEffect, useState } from "react";
import { getOpenSponsorsList } from "helpers/jiraya_helper";
import useSWR from "swr";
import { GET_OPEN_SPONSORS_LIST } from "helpers/jiraya_url";
import { Container, Row, Col, Card, CardBody, CardLink } from "reactstrap";
import GalleryContext from "store/galleryContext";
import { ACTIVITY } from "./enums";
const SponsorCard = ({ data, height, size, handleActivity }) => {
  return (
    <Row className="mt-3 d-flex justify-content-center gx-0">
      {data?.map((item) => {
        return (
          <Col lg={2} md={3} sm={6} xs={6}>
            <div
              style={{
                width: "100%",
                border: "none",
              }}
              className="text-center m-auto"
              onClick={() =>
                handleActivity(ACTIVITY.SPONSOR_VISIT, null, item.id)
              }
            >
              <a
                href={
                  (item?.sponsor_url).includes("https://")
                    ? item?.sponsor_url
                    : `https://" + ${item?.sponsor_url}`
                }
                target="_blank"
              >
                <img
                  alt={item?.name}
                  className="m-auto"
                  src={item?.sponsor_image_url}
                  // width={width}
                  style={{ objectFit: "contain" }}
                  height={height}
                  width={"100%"}
                />
              </a>
              <CardBody className="text-center">
                {/* <CardLink href={item?.sponsor_url}>{item?.name}</CardLink> */}
                <CardLink
                  href={
                    (item?.sponsor_url).includes("https://")
                      ? item?.sponsor_url
                      : `https://" + ${item?.sponsor_url}`
                  }
                  target="_blank"
                >
                  <strong>{item.name}</strong>
                  <br />
                  {item.title}
                </CardLink>
              </CardBody>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};
const SponsorsList = (props) => {
  const { eventId } = props;
  const [sponsorList, setSponsorList] = useState();
  const { handleActivity } = useContext(GalleryContext);
  const { data, error, isLoading } = useSWR(
    [GET_OPEN_SPONSORS_LIST, eventId],
    () => getOpenSponsorsList({ params: { event_id: eventId } })
  );

  useEffect(() => {
    setSponsorList(data?.data);
  }, [data]);
  const mainSponsor = sponsorList?.filter((item) => item.type === 0);
  const coSponsor = sponsorList?.filter((item) => item.type === 1);
  return (
    <>
      {(mainSponsor?.length > 0 || coSponsor?.length > 0) && (
        <Container>
          {/* <Row>
            <Col className="d-flex justify-content-center mt-4 mt-md-5">
              <h2>Sponsored By</h2>
            </Col>
          </Row> */}
          <SponsorCard
            data={mainSponsor}
            height={"90px"}
            size={"6rem"}
            handleActivity={handleActivity}
          />
          <br />
          <SponsorCard
            data={coSponsor}
            height={"90px"}
            size={"5rem"}
            handleActivity={handleActivity}
          />
        </Container>
      )}
    </>
  );
};

export default SponsorsList;
