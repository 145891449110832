import { Field, Formik, Form } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Label, Input, Col, Row, FormGroup, FormFeedback } from "reactstrap";
import UserContext from "store/guest_user/userContext";
import * as Yup from "yup";

const CompleteProfile = () => {
  const { isUserLoading, handleOnUpdateProfile, user } =
    useContext(UserContext);
  const location = useLocation();
  const eventId = useMemo(() => {
    try {
      const c = location.pathname.split("/");
      return Number(c[c.length - 1]);
    } catch (err) {
      console.log(err);
      return -1;
    }
  }, [location]);

  const handleSubmit = (values) => {
    const userProfile = { ...values };
    handleOnUpdateProfile(userProfile);
  };

  const ProfileSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First Name is Required"),
    // email: Yup.string().email("Invalid email").required("Email is Required"),
    tnc: Yup.boolean().oneOf([true], "You must accept the terms to continue"),
  });

  const [defaultData, setDefaultData] = useState({});
  useEffect(() => {
    let registeredData = localStorage.getItem("registration-data");
    if (registeredData) {
      registeredData = JSON.parse(registeredData);
      setDefaultData({ fullName: registeredData.name });
    }
  }, []);

  return (
    <>
      <div className="w-100 text-center complete-profile">
        <div>
          <strong className=" ">Complete your Profile</strong>
        </div>
        {!isUserLoading && (
          <div className="mt-3">
            <Formik
              validationSchema={ProfileSchema}
              initialValues={{
                mobile_number: user.phoneNumber || user.mobile_number,
                name: user.displayName || user.name,
                email: user.email,
                linkedin_profile: user.linkedin_profile,
                tnc: false,
                ...defaultData,
              }}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <div>
                  <Form>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <div className="text-start ms-1">
                            <small className="form-label  ">Name*</small>
                          </div>

                          <Field
                            placeholder="Full Name"
                            as={Input}
                            name="name"
                            className={"me-2"}
                            invalid={errors.name}
                          />
                          {errors.name && touched.name ? (
                            <FormFeedback className="text-start">
                              {errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row className="mt-3">
                    <Col>
                      <Field
                        placeholder="Email Id"
                        as={Input}
                        name="email"
                        className={"me-2"}
                        invalid={errors.email}
                      />
                      {errors.email && touched.email ? (
                        <FormFeedback className="text-start">
                          {errors.email}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row> */}
                    <Row className="mt-3">
                      <Col>
                        <div>
                          <div className="text-start ms-1">
                            <small className="form-label  ">
                              Mobile Number*
                            </small>
                          </div>
                          <Field
                            as={Input}
                            name="mobile_number"
                            className={"me-2"}
                            disabled="true"
                          />
                        </div>
                      </Col>
                    </Row>

                    {eventId === 66389 && (
                      <Row className="mt-3">
                        <Col>
                          <div>
                            <div className="text-start ms-1">
                              <small className="form-label  ">
                                LinkedIn Profile
                              </small>
                            </div>
                            <Field
                              as={Input}
                              name="linkedin_profile"
                              className={"me-2"}
                              placeholder="LinkedIn Profile"
                            />
                          </div>
                        </Col>
                      </Row>
                    )}

                    {/* <Row className="mt-3">
                    <Col>
                      <div>
                        <div className="text-start ms-1">
                          <small className="form-label  ">Instagram ID*</small>
                        </div>
                        <Field
                          as={Input}
                          name="instagramId"
                          className={"me-2"}
                          placeholder="Instagram ID"
                        />
                      </div>
                    </Col>
                  </Row> */}
                    <div className="mt-3 d-flex justify-content-start">
                      <FormGroup check>
                        <Field
                          as={Input}
                          type="checkbox"
                          name="tnc"
                          className={"me-2"}
                          invalid={errors.tnc}
                        />
                        <Label check>
                          I have read and I agree to the{" "}
                          <a
                            href="https://fotoowl.ai/privacypolicy/"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                        </Label>
                        {errors.tnc && touched.tnc ? (
                          <FormFeedback className="text-start">
                            {errors.tnc}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    </div>
                    <div className="mt-4 w-100">
                      <button
                        type="submit"
                        className="btn btn-fotoowl btn-rounded-2 w-100"
                        disabled={isSubmitting}
                      >
                        <strong>Create My Profile</strong>
                      </button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};

export default CompleteProfile;
