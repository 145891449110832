import UploadZone from "components/Events/Client/Upload/upload_zone";
import { useContext, useEffect } from "react";
import UserContext from "store/guest_user/userContext";

const GuestUploadPage = ({
  onUpload,
  onCancel,
  eventId,
  handleOnOpenMyUploads,
  isAutoApproveUpload,
}) => {
  const { isUserLoading, user, handleOpenLoginModal } = useContext(UserContext);

  useEffect(() => {
    if (!isUserLoading && !user) {
      handleOpenLoginModal(onCancel);
    }
  }, [user]);
  return (
    <>
      <UploadZone
        onCancel={onCancel}
        onUpload={onUpload}
        eventId={eventId}
        handleOnOpenMyUploads={handleOnOpenMyUploads}
        isAutoApproveUpload={isAutoApproveUpload}
      />
    </>
  );
};

export default GuestUploadPage;
