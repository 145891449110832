import {
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  Modal,
  ModalBody,
} from "reactstrap";
// import { initFirebaseBackend } from "helpers/firebase_helper";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  onAuthStateChanged,
  signOut,
  updateProfile,
  updateEmail,
} from "firebase/auth";
import auth from "../../store/guest_user/helper";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import useSWR from "swr";
import { GET_OPEN_USER_LOCATION } from "helpers/jiraya_url";
import { getOpenUserLocation } from "helpers/jiraya_helper";
import { UserProvider } from "store/guest_user/userContext";
import UserContext from "store/guest_user/userContext";
import { toast } from "react-toastify";
import CompleteProfile from "components/Events/Client/Auth/CompleteProfile";
import { patchGuestUserProfile } from "helpers/jiraya_helper";
import { getGuestUser } from "helpers/jiraya_helper";

const Login = () => {
  useEffect(() => {
    let registeredData = localStorage.getItem("registration-data");
    if (registeredData) {
      registeredData = JSON.parse(registeredData);
      setMobileNumber(registeredData.mobile_number);
    }
  }, []);
  const [confirmationResult, setConfirmationResult] = useState();
  const [isCaptchaOK, setIsCaptchaOK] = useState(false);
  const [otp, setOtp] = useState();
  const handleChangeOTP = (v) => {
    setOtp(v);
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleSetUser } = useContext(UserContext);

  useEffect(() => {
    if (auth && !confirmationResult) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: function (_response) {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // updateSignInButtonUI();
            // console.log("sigin");

            onValidCaptcha();
          },
          "expired-callback": function () {
            // Response expired. Ask user to solve reCAPTCHA again.
            // updateSignInButtonUI();
            console.log("expire");
          },
        }
      );

      window.recaptchaVerifier.render().then(function (widgetId) {
        window.recaptchaWidgetId = widgetId;
      });
      window.recaptchaVerifier.verify().then((response) => {
        console.log(response);
      });
    }
  }, [auth, confirmationResult]);

  const [mobileNumber, setMobileNumber] = useState();
  const onMobilInputChange = (val) => {
    setMobileNumber(val);
    // setErrorMessages({ ...errorMessages, mobileNumber: null });
  };
  const handleOnChangeMobile = () => {
    setOtp();
    setMobileNumber();
    setConfirmationResult();
  };

  const handleOnVerifyOTP = (otp) => {
    setIsSubmitting(true);
    confirmationResult
      .confirm(otp)
      .then((response) => {
        // console.log(response);
        toast.success("OTP Verified Successfully");
        handleSetUser(response.user);
      })
      .catch((err) => {
        setIsSubmitting(false);
        setOtp("");
        console.log(err);
        toast.error(err.code);
      });
  };

  const handleOnGetOTP = () => {
    const appVerifier = window.recaptchaVerifier;
    setIsSubmitting(true);
    signInWithPhoneNumber(auth, mobileNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setConfirmationResult(confirmationResult);
        toast.success(`OTP sent successfully`);
        setIsSubmitting(false);
        // ...
      })
      .catch((error) => {
        console.log(error);
        toast.error(`Failed to send OTP, error code ${error.code}`);
        setConfirmationResult();
        // Error; SMS not sent
        // ...
        setIsSubmitting(false);
      });
  };
  const onValidCaptcha = () => {
    setIsCaptchaOK(true);
  };

  return (
    <>
      <div className="w-100 text-center login">
        <div>
          <strong className=" ">Sign In to Continue</strong>
        </div>

        <small>Verify your mobile number to continue uploading photos</small>
      </div>
      {mobileNumber && confirmationResult ? (
        <VerifyOTP
          handleOnVerifyOTP={handleOnVerifyOTP}
          mobileNumber={mobileNumber}
          handleChangeOTP={handleChangeOTP}
          otp={otp}
          isSubmitting={isSubmitting}
          handleOnChangeMobile={handleOnChangeMobile}
          handleOnGetOTP={handleOnGetOTP}
        />
      ) : (
        <GetOTP
          mobileNumber={mobileNumber}
          onMobilInputChange={onMobilInputChange}
          isCaptchaOK={isCaptchaOK}
          handleOnGetOTP={handleOnGetOTP}
          isSubmitting={isSubmitting}
        />
      )}
      <div className="mt-4 d-flex justify-content-center flex-column align-items-center">
        <div id="recaptcha-container"></div>
      </div>
    </>
  );
};

const VerifyOTP = ({
  handleOnVerifyOTP,
  mobileNumber,
  otp,
  handleChangeOTP,
  isSubmitting,
  handleOnChangeMobile,
  handleOnGetOTP,
}) => {
  const [resendTimer, setResendTimer] = useState(30);
  const handleOnResendOTP = () => {
    setResendTimer(30);
    handleOnGetOTP();
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setResendTimer((p) => {
        const newT = p - 1;
        if (newT <= 0) {
          clearInterval(intervalId);
        }
        return newT;
      });
    }, 1000);
  }, []);
  return (
    <>
      <FormGroup>
        <div className="mt-2">
          {mobileNumber}
          <i className="mdi mdi-pencil ms-1" onClick={handleOnChangeMobile} />
        </div>
      </FormGroup>
      <FormGroup>
        <Input
          // type="password"
          pattern="[0-9]*"
          inputMode="numeric"
          className="pin-input mt-4"
          maxLength={6}
          min={0}
          max={999999}
          onChange={(e) => handleChangeOTP(e.target.value)}
          value={otp}
          // disabled={isSubmitting}
        />
      </FormGroup>
      {resendTimer === 0 ? (
        <small
          onClick={handleOnResendOTP}
          style={{ textDecoration: "underline" }}
        >
          Resend OTP
        </small>
      ) : (
        <small>resend OTP in {resendTimer}s</small>
      )}

      <div className="px-2 w-100 mt-3">
        <button
          // ref={signInButtonRef}
          id="sign-in-button"
          className="btn btn-fotoowl btn-rounded-2 mt-2 px-4 w-100"
          disabled={isSubmitting || !otp || otp.length != 6}
          onClick={() => {
            handleOnVerifyOTP(otp);
          }}
        >
          Verify
        </button>
      </div>
    </>
  );
};

const GetOTP = ({
  mobileNumber,
  onMobilInputChange,
  handleOnGetOTP,
  isCaptchaOK,
  isSubmitting,
}) => {
  const { t } = useTranslation();
  const { data: countryData } = useSWR(
    GET_OPEN_USER_LOCATION,
    getOpenUserLocation
  );

  return (
    <div className="mt-3 px-2 ">
      <PhoneInput
        placeholder={t("Enter Mobile Number")}
        value={mobileNumber}
        // onCountryChange={handleOnCountryChange}
        onChange={onMobilInputChange}
        defaultCountry={countryData?.data?.country}
        numberInputProps={{
          className: classNames("form-control mobile-number"),
        }}
      />
      <div className="w-100 mt-3 text-center">
        <button
          // ref={signInButtonRef}
          id="sign-in-button"
          className="btn btn-fotoowl btn-rounded-2 mt-2 px-4 w-100"
          onClick={handleOnGetOTP}
          disabled={!isCaptchaOK || isSubmitting}
        >
          Get OTP
        </button>
        <small className="">
          check your message inbox or wait for 1-2 mins{" "}
        </small>
      </div>
    </div>
  );
};

const LoginModal = ({ isOpen, onCancelCallback }) => {
  return (
    <Modal isOpen={isOpen} centered size="md" zIndex={1503}>
      <ModalBody className="p-3">
        <div className="d-flex justify-content-end">
          <button
            className="btn-close"
            onClick={() => {
              onCancelCallback();
            }}
          />
        </div>

        <Login />
      </ModalBody>
    </Modal>
  );
};

const CompleteProfileModal = ({ isOpen }) => {
  return (
    <Modal isOpen={isOpen} centered size="md" zIndex={1505}>
      <ModalBody className="p-3">
        <CompleteProfile />
      </ModalBody>
    </Modal>
  );
};

export const User = ({ children }) => {
  const [user, setUser] = useState();
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openCompleteProfileModal, setOpenCompleteProfileModal] =
    useState(false);
  const handleSetUser = (_user) => {
    if (_user) {
      getGuestUser().then((response) => {
        if (response.ok) {
          const userData = { ..._user, ...response.data };
          setUser(userData);
          handleOnCloseLoginModal();
        } else {
          setUser();
        }
      });
    } else {
      setUser();
    }
    setIsUserLoading(false);
  };
  const [onCancelCallback, setOnCancelCallback] = useState(() => {});

  const handleOnCloseLoginModal = () => {
    setOpenLoginModal(false);
  };

  const handleOpenLoginModal = (onCancelCallback) => {
    const handleOnCloseLoginModal = () => {
      setOpenLoginModal(false);
      onCancelCallback();
    };
    setOnCancelCallback(() => handleOnCloseLoginModal);
    setOpenLoginModal(true);
  };

  useEffect(() => {
    if (user) {
      handleOnCloseLoginModal();
      if (!user.name || !user.mobile_number) {
        setOpenCompleteProfileModal(true);
      }
    }
  }, [user]);

  const handleOnUpdateProfile = async (userProfile) => {
    // updateProfile(auth.currentUser, {
    //   displayName: userProfile.name,
    // })
    // .then(async () => {
    try {
      const response = await patchGuestUserProfile(userProfile);
      if (response.ok) {
        toast.success("Profile Updated Successfully");
        setOpenCompleteProfileModal(false);
      } else {
        toast.error(response.message);
        setOpenCompleteProfileModal(false);
      }
    } catch (error) {
      toast.error("Failed to Update Profile");
    }
    // })
    // .catch((error) => {
    //   console.log(error);
    //   toast.error("Failed to Update Profile");
    // });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      handleSetUser(user);
    });
  }, []);

  const handleLogoutUser = () => {
    return new Promise((resolve, reject) => {
      signOut(auth)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject();
        });
    });
  };
  return (
    <UserProvider
      value={{
        isUserLoading,
        user,
        handleSetUser,
        handleLogoutUser,
        openLoginModal,
        handleOpenLoginModal,
        handleOnUpdateProfile,
      }}
    >
      <LoginModal isOpen={openLoginModal} onCancelCallback={onCancelCallback} />
      <CompleteProfileModal isOpen={openCompleteProfileModal} />
      {children}
    </UserProvider>
  );
};

export default LoginModal;
