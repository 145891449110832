import React, { useEffect, useState } from "react";
import axios from "axios";

const key = "country-data-expiry";

function setWithExpiry(key, value) {
  const expiry = new Date().getTime() + 6 * 60 * 60 * 1000; // 6 hours in milliseconds
  const item = {
    value: value,
    expiry: expiry,
  };

  localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
  const item = localStorage.getItem(key);

  if (!item) {
    return null;
  }

  const parsedItem = JSON.parse(item);
  const { value, expiry } = parsedItem;

  // Check if the expiry time is greater than the current time
  if (new Date().getTime() > expiry) {
    localStorage.removeItem(key); // Optional: Remove expired item from localStorage
    return null;
  }

  return value;
}

const useCountry = () => {
  const [country, setCountry] = useState();
  useEffect(() => {
    var _country = getWithExpiry(key);
    if (_country) {
      setCountry(JSON.parse(_country));
    } else {
      axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;
          setCountry(data);
          setWithExpiry(key, JSON.stringify(data));
        })
        .catch((error) => {
          setCountry({ country_code: "US", city: "" });
          console.log(error);
        });
    }
  }, []);
  return country;
};

export default useCountry;
