import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};
// const firebaseBackend = initFirebaseBackend(firebaseConfig);
const app = initializeApp(firebaseConfig);

const messaging = async () => {
  const supported = await isSupported();
  return supported ? getMessaging(app) : null;
};

const auth = getAuth(app);

export const fetchToken = async () => {
  try {
    const fcmMessaging = await messaging();

    if (fcmMessaging) {
      const token = await getToken(fcmMessaging, {
        vapidKey: process.env.REACT_APP_VAPIDKEY,
      });
      console.info("FCM:", token);
      localStorage.setItem("fcm-token", token);
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register(
            "/firebase-messaging-sw.js?env=" +
              encodeURIComponent(JSON.stringify(process.env)),
            {
              scope: "/firebase-cloud-messaging-push-scope",
            }
          )
          .then((registration) => {
            console.info(
              "Service Worker registered with scope:",
              registration.scope
            );
          })
          .catch((err) => {
            console.error("Service Worker registration failed:", err);
          });
      }
      return token;
    }
    return null;
  } catch (err) {
    console.error("An error occurred while fetching the token:", err);
    return null;
  }
};

export default auth;
