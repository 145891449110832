import axios from "axios";
// import { getFirebaseBackend } from "./firebase_helper";
// import auth from "store/guest_user/helper";
import { getAuth } from "firebase/auth";

//apply base url for axios
const API_URL = process.env.REACT_APP_APIURL;
const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  let bearer_token = null;
  if (url.includes("/guest-user")) {
    const auth = getAuth();
    bearer_token = await auth.currentUser.getIdToken();
    config = {
      ...config,
      headers: { Authorization: `Bearer ${bearer_token}` },
    };
  } else {
    console.log("Authorization header not added for open routes ");
  }

  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  let bearer_token = null;
  if (url.includes("/guest-user")) {
    const auth = getAuth();
    bearer_token = await auth.currentUser.getIdToken();
  }
  config = {
    ...config,
    headers: { Authorization: `Bearer ${bearer_token}` },
  };
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  let bearer_token = null;
  if (url.includes("/guest-user")) {
    const auth = getAuth();
    bearer_token = await auth.currentUser.getIdToken();
  }
  config = {
    headers: { Authorization: `Bearer ${bearer_token}` },
  };
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

// export async function del(url, config = {}) {
//   const fireBaseBackend = getFirebaseBackend()
//   const bearer_token = await fireBaseBackend.get_id_token()
//   config = {
//     ...config,
//     headers: { Authorization: `Bearer ${bearer_token}` }
//   };
//   return await axiosApi
//     .delete(url, { ...config })
//     .then(response => response.data)
// }

export async function del(url, config = {}) {
  let bearer_token = null;
  if (url.includes("/guest-user")) {
    const auth = getAuth();
    bearer_token = await auth.currentUser.getIdToken();
  }
  config = {
    ...config,
    headers: { Authorization: `Bearer ${bearer_token}` },
  };
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

export async function patch(url, data, config = {}) {
  let bearer_token = null;
  if (url.includes("/guest-user")) {
    const auth = getAuth();
    bearer_token = await auth.currentUser.getIdToken();
  }

  config = {
    ...config,
    headers: { Authorization: `Bearer ${bearer_token}` },
  };
  return axiosApi
    .patch(url, data, { ...config })
    .then((response) => response.data);
}
