import React, { useState, useEffect } from "react";
import { getOpenEvent } from "helpers/jiraya_helper";
import { useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { GET_EVENT_OPEN } from "helpers/jiraya_url";
import loader from "assets/images/icons/loader.svg";
import { GET_COLLECTION_LIST_OPEN } from "helpers/jiraya_url";
import { getOpenCollectionList } from "helpers/jiraya_helper";
import eventDeleted from "assets/images/illustrations/event-delete.svg";
import EventGallery from "components/Events/Client/event_gallery";
import { createGridFunctions } from "components/Events/utils";
import { GALLERY_PREVIEW_MODE, ACTIVITY } from "components/Events/Client/enums";
import { basic, marathon2 } from "./desginTemplates2";
import { GALLERY_ACCESS_LEVEL } from "components/Events/Client/enums";
import { GalleryExpired } from "components/Events/Client/alerts";
import { useRequestRedirect } from "components/Events/Client/cachedData";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const isApple = /iPhone|iPad|iPod/i.test(navigator.userAgent);
const isPreview = process.env.REACT_APP_APPTYPE === "dashboard";
const GalleryPage = (props) => {
  const { i18n } = useTranslation();
  const params = useParams();
  const [eventId, setEventId] = useState(params.eventId || props.eventId);
  // const eventId = ;
  const queryParams = new URLSearchParams(window.location.search);
  const pass_key = queryParams.get("pass_key") || props.passKey;
  const share_key = queryParams.get("share_key");
  const request_id = queryParams.get("request_id");
  const request_key = queryParams.get("request_key");
  const _albumSelection = queryParams.get("album_selection");
  const noTrackActivity = Number(queryParams.get("no-activity"));
  const teamView = queryParams.get("master_view");
  const orgId = queryParams.get("org_id");
  const filterText = queryParams.get("filter");
  const showOnlyGuestUpload = queryParams.get("show-guest-upload");
  const isOperatorRegistrationForm = queryParams.get(
    "operator-registeration-form"
  );
  const isPortfolio = props.isPortfolio;
  let pinVerified = params.verified === "1";
  let accessLevel = params.accessLevel && Number(params.accessLevel);
  let access_key = params.accessKey;
  const showAdvocacyPoster = props.showAdvocacyPoster;
  const sponsorIq = props.queryData;
  const queryCollectionId = props.queryCollectionId;
  const queryCollectionKey = props.queryCollectionKey;
  const showTeam = props.showTeam;
  const { teamId, playerId } = props;

  const handleOnChangeEventId = (id) => {
    setEventId(id);
  };

  var isError = false;
  const [albumSelection, setAlbumSelection] = useState(
    _albumSelection == "true" ? true : false
  );
  const handleChangeAlbumSelection = (v) => {
    setAlbumSelection(v);
  };
  /////// disable right click
  const disable_click = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    document.addEventListener("contextmenu", disable_click);
    console.log(`isPreview: ${isPreview}`);
    return () => document.removeEventListener("contextmenu", disable_click);
  }, []);

  //////////////////////// Gallery /////////////////////////////////////////

  //////////////////////////// Keys //////////////////////////////////////////
  const [galleryAccessControl, setGalleryAccessControl] = useState({
    mode: GALLERY_PREVIEW_MODE.CLIENT,
    pinRequired: !pinVerified,
    pinValidated: pinVerified,
    pinAccessLevel: accessLevel,
    pin: pass_key || share_key,
    requestKey: request_key,
    requestId: request_id,
    access_key: access_key,
  });

  const handleOnValidPin = (accessLevel, pin) => {
    setGalleryAccessControl({
      ...galleryAccessControl,
      pinValidated: true,
      pinAccessLevel: accessLevel,
      pin,
      requestKey: request_key,
      requestId: request_id,
    });
  };
  // Hardcoded fix for pragmaticglue@gmail.com kittumangayi event id 4060
  useEffect(() => {
    if (eventId == 4060) {
      setGalleryAccessControl({
        mode: GALLERY_PREVIEW_MODE.CLIENT,
        pinRequired: false,
        pin: 6509,
        pinAccessLevel: GALLERY_ACCESS_LEVEL.FULL,
        requestKey: request_key,
        requestId: request_id,
      });
    }
    if (props.passKey && props.eventId) {
      setGalleryAccessControl({
        mode: GALLERY_PREVIEW_MODE.CLIENT,
        pinRequired: false,
        pin: props.passKey,
        pinAccessLevel: GALLERY_ACCESS_LEVEL.FULL,
        requestKey: request_key,
        requestId: request_id,
      });
    }
    // else if (eventId == 376) {
    //   setGalleryAccessControl({
    //     mode: GALLERY_PREVIEW_MODE.CLIENT,
    //     pinRequired: false,
    //     pin: 2382,
    //     pinAccessLevel: GALLERY_ACCESS_LEVEL.FULL,
    //   });

    // }
  }, []);

  const [showAllImages, setShowAllImages] = useState(request_id ? false : true);

  //////////////////////////// GET EVENT DETAILS //////////////////////////////////////////

  const { data: eventData, error: eventError } = useSWR(
    [GET_EVENT_OPEN, eventId],
    () => getOpenEvent({ params: { event_id: eventId } }),
    { shouldRetryOnError: false }
  );
  isError = eventError;

  // load request ID from local for event id
  useRequestRedirect({
    eventId,
    request_id,
    galleryAccessControl,
    isPortfolio,
    request_key,
    disableCache: showAdvocacyPoster,
    eventData: eventData?.data,
  });

  //////////////////////////// GET COLLECTION LIST //////////////////////////////////////////
  const getCollectionKey = () => {
    if (!eventData) return null;
    if (
      galleryAccessControl.pinRequired &&
      !galleryAccessControl.pinValidated &&
      !galleryAccessControl.pin
    )
      return null;
    else {
      return [
        GET_COLLECTION_LIST_OPEN,
        galleryAccessControl.pin,
        access_key,
        albumSelection,
        eventData.data.album_collection_id_list.join(),
        teamView,
        filterText,
      ];
    }
  };
  const { data: collectionData, error: collectionError } = useSWR(
    getCollectionKey,
    (
      url,
      key,
      access_key,
      albumSelection,
      collection_id_list_str,
      team_view,
      filter_text
    ) =>
      getOpenCollectionList({
        params: {
          event_id: eventId,
          key,
          access_key,
          album_selection: albumSelection,
          collection_id_list_str: collection_id_list_str,
          team_view,
          filter_text,
        },
      })
  );

  isError = collectionError;

  //////////////////////////////////// Design //////////////////////////////
  const [designSettings, setDesignSettings] = useState();
  const [designDetails, setDesignDetails] = useState();

  const isLoading = !((eventData && designSettings) || eventError);
  console.log(`Gallery-Page isLoading ${isLoading}`);
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(`design-settings-${eventId}`));
    const metadata = JSON.parse(
      localStorage.getItem(`event-metadata-${eventId}`)
    );
    if (isPreview && data) {
      data.designSettings.galleryProps = {
        ...data.designSettings.galleryProps,
        ...createGridFunctions(data.designSettings.gridStyle),
      };
      data.designSettings = metadata
        ? { ...data.designSettings, ...metadata }
        : data.designSettings;

      setDesignDetails(data.designDetails);
      setDesignSettings(data.designSettings);
      setGalleryAccessControl({
        ...galleryAccessControl,
        mode: data.designSettings.viewMode.mode,
      });
    } else if (eventData?.data) {
      var _designSettings, style;
      var _designDetails = {};
      if (eventData.data.gallery_style) {
        style = eventData.data.gallery_style.style;
        _designDetails.id = eventData.data.gallery_style_id;
      } else {
        style = basic.style;
      }
      if (!style) {
        console.log("No style found");
      } else {
        _designSettings = style.designSettings.v1;
        _designDetails = { ...style, ..._designDetails };
        delete _designDetails.designSettings;

        // JSON.parse(localStorage.getItem(`design-settings-${eventId}`)) || {};

        const metaData = eventData.data.metadata_json;
        _designSettings = {
          ..._designSettings,
          ...metaData,
        };
        _designSettings.galleryProps = {
          ..._designSettings.galleryProps,
          ...createGridFunctions(_designSettings.gridStyle),
        };
        setDesignSettings(_designSettings);
        setDesignDetails(_designDetails);
      }
      // language
      const language = eventData.data.language;
      console.log(`setting language to ${language}`);
      if (language && language !== "auto") {
        i18n.changeLanguage(language);
      }
    }
  }, [eventData]);

  const getComponent = () => {
    if (isLoading) {
      return (
        <div className="loader loader-full">
          <img src={loader} />
        </div>
      );
    } else if (eventError?.response?.status === 404) {
      return (
        <div className="ht-80 w-100 d-flex flex-column justify-content-center align-items-center">
          <img className="w-50 d-md-none " src={eventDeleted} />
          <img className="w-25 d-none d-md-block " src={eventDeleted} />
          <h3 className="mt-5" style={{ color: "red" }}>
            ⚠️ Gallery is Deleted !! ☹️{" "}
          </h3>
          <div>Please contact the Event Admin</div>
        </div>
      );
    } else {
      if (!eventData?.ok) {
        return <div>{eventData?.data.message}</div>;
      } else {
        // set gallery styles
        const style = eventData.data.share_style;
        if (style.color.value === "black") {
          document.body.setAttribute("data-layout-mode", "dark");
        }

        // check gallery expiry
        const validTillTimestamp = eventData?.data?.event_valid_till;
        var validTillDateTime = new Date(0);
        var galleryExpired = false;
        if (validTillTimestamp) {
          const currentTimestamp = Math.floor(new Date().getTime() / 1000);
          if (validTillTimestamp + 100000 < currentTimestamp) {
            galleryExpired = true;
          }

          validTillDateTime.setUTCSeconds(validTillTimestamp);
        }

        var modCollectionData = [];
        let peopleIndex = 0;
        if (collectionData?.ok) {
          if (
            !albumSelection ||
            (eventData.data.album_collection_id_list &&
              eventData.data.album_collection_id_list.includes(-1))
          ) {
            peopleIndex = 1;
            modCollectionData = [
              {
                id: -1,
                name: "All",
                image_count: eventData?.data?.image_count,
                video_count: eventData?.data?.video_count,
                link_video_count: eventData?.data?.link_video_count,
              },
            ];
          }
          const isGuestUploadAllowed =
            eventData?.data?.guest_upload_restriction == 1 ? true : false;
          if (isGuestUploadAllowed) {
            peopleIndex = 2;
            modCollectionData.push({
              id: -4,
              name: "Guest Uploads",
              image_count: 10,
              video_count: 10,
              link_video_count: 10,
            });
          }
          collectionData.data.forEach((item) => {
            if (item.name === "People" && request_id && !showAllImages) {
              const peopleCol = {
                ...item,
                image_count: 1,
                name: "My Photos",
              };
              modCollectionData.splice(peopleIndex, 0, peopleCol);
            } else {
              modCollectionData.push(item);
            }
          });
        } else {
          modCollectionData = [
            {
              id: -1,
              name: "All",
              image_count: eventData?.image_count,
              video_count: eventData?.video_count,
              link_video_count: eventData.link_video_count,
            },
          ];
        }

        return (
          <>
            {galleryExpired && <GalleryExpired />}
            <EventGallery
              eventId={eventId}
              eventData={eventData.data}
              collectionData={modCollectionData}
              galleryAccessControl={galleryAccessControl}
              handleOnValidPin={handleOnValidPin}
              albumSelection={albumSelection}
              handleChangeAlbumSelection={handleChangeAlbumSelection}
              themeType={designDetails.type}
              trackActivity={!noTrackActivity && !isPreview}
              galleryExpired={galleryExpired}
              isPortfolio={isPortfolio}
              access_key={access_key}
              teamView={teamView}
              orgId={orgId}
              filterText={filterText}
              handleOnChangeEventId={handleOnChangeEventId}
              showAdvocacyPoster={showAdvocacyPoster}
              showOnlyGuestUpload={showOnlyGuestUpload}
              isOperatorRegistrationForm={isOperatorRegistrationForm}
              showTeam={showTeam}
              playerGalleryProps={{ teamId, playerId }}
              {...designSettings}
              sponsorIq={sponsorIq}
              queryCollectionId={queryCollectionId}
              queryCollectionKey={queryCollectionKey}
            />
          </>
        );
      }
    }
  };
  /////////////////////////////////////////////////////////////////////////////////////////

  return <React.Fragment>{getComponent()}</React.Fragment>;
};

export default GalleryPage;
