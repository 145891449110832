import React, { useMemo } from "react";
import teamRoutes from "routes/teamRoutes";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { Button, ButtonGroup } from "reactstrap";
import { useShareModal } from "components/Modal/ShareModal";
import ShareModal from "components/Modal/ShareModal";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
const Teams = (props) => {
  const galleryConfig = props;
  const shareModalProps = useShareModal();
  const { eventId } = useParams();
  const { origin, search } = window.location;
  const searchParam = new URLSearchParams(search);

  const homelocation = useMemo(() => {
    searchParam.delete("team-name");
    searchParam.delete("player-id");
    searchParam.delete("all-players");
    return `${origin}/teams/${eventId}?${searchParam.toString()}`;
  }, []);

  const gotoHome = () => {
    window.location.href = homelocation;
  };

  return (
    <div className="">
      <div className="d-flex justify-content-end mx-3 mx-md-5 mb-4">
        <ButtonGroup>
          <Button size="sm" onClick={gotoHome}>
            <i className="mdi mdi-home " /> Home
          </Button>
          <Button size="sm" onClick={shareModalProps.toggleModal}>
            <i className="mdi mdi-share-variant " /> Share
          </Button>
        </ButtonGroup>
      </div>
      <ShareModal {...shareModalProps} />
      <Router>
        <Switch>
          {teamRoutes.map((route, idx) => (
            <Route
              path={route.path}
              key={idx}
              component={() => <route.component {...galleryConfig} />}
            />
          ))}
        </Switch>
      </Router>
    </div>
  );
};

export default Teams;
