import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Spinner, Button } from "reactstrap";
import TeamPlayerCard from "./team_player_card";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { GET_TEAM_PLAYERS } from "helpers/jiraya_url";
import { getTeamPlayers } from "helpers/jiraya_helper";
import { useShareModal } from "components/Modal/ShareModal";
import ShareModal from "components/Modal/ShareModal";
import allteamimage from "assets/images/illustrations/sports.jpg";

const STORAGE_HOST = process.env.REACT_APP_STORAGE_HOST;

const TeamPlayersList = () => {
  const location = useLocation();
  const { teamId } = useParams();
  const shareModalProps = useShareModal();
  const searchParam = new URLSearchParams(location.search);
  const teamName = searchParam.get("team-name");

  const { data: players, error } = useSWR(
    teamId ? [GET_TEAM_PLAYERS, teamId] : null,
    () => getTeamPlayers({ params: { team_id: teamId } })
  );

  const isLoading = !(error || players);

  if (isLoading) {
    return (
      <div className="text-center p-5">
        <Spinner color="primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-danger p-3">
        Failed to load team players
      </div>
    );
  }

  const allPlayersList = players?.data?.players
    .map((item) => item.id)
    .join(",");

  return (
    <>
      {/* <div className="d-flex justify-content-end my-3 mx-3">
        <button
          color="success"
          onClick={shareModalProps.toggleModal}
          className="btn btn-success btn-rounded-2 px-3"
        >
          <i className="mdi mdi-share-variant" /> Share
        </button>
      </div>
      <ShareModal {...shareModalProps} /> */}
      <Row className="text-center">
        <h3>{teamName}</h3>
      </Row>
      <Row className="g-4 my-4 mx-1 mx-md-3">
        <Col>
          <Link
            to={`${location.pathname}/-1${location.search}&all-players=${allPlayersList}`}
            className="text-decoration-none"
          >
            <TeamPlayerCard
              image={allteamimage}
              name={"All Team"}
              description={""}
            />
          </Link>
        </Col>
        {players?.data?.players.map((player) => (
          <Col key={player.id} xs={6} sm={6} md={4} lg={2}>
            <Link
              to={`${location.pathname}/${player.id}${location.search}`}
              className="text-decoration-none"
            >
              <TeamPlayerCard
                image={STORAGE_HOST + encodeURI(player.image_path)}
                name={player.name}
                description={player.description}
              />
            </Link>
          </Col>
        ))}
      </Row>
    </>
  );
};

TeamPlayersList.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export default TeamPlayersList;
