import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { QRCodeSVG } from "qrcode.react"; // Lightweight QR code library

export const useShareModal = () => {
  // State to control the share modal
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

  // Extract the current URL
  const currentUrl = window.location.href;
  // Build WhatsApp share URL
  const whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  // Function to copy current URL to clipboard
  const copyLink = () => {
    navigator.clipboard.writeText(currentUrl).then(
      () => toast.success("Link Copied Successfully"),
      () => toast.error("Failed to copy link")
    );
  };

  return { modalOpen, toggleModal, copyLink, whatsappLink, currentUrl };
};

const ShareModal = ({
  modalOpen,
  toggleModal,
  copyLink,
  whatsappLink,
  currentUrl,
}) => {
  return (
    <Modal isOpen={modalOpen} toggle={toggleModal} centered>
      <ModalHeader toggle={toggleModal}>Share</ModalHeader>
      <ModalBody>
        <p>Share this page</p>
        <input
          type="text"
          className="form-control"
          value={currentUrl}
          readOnly
        />
        {/* QR Code generated using qrcode.react, centered using Bootstrap classes */}
        <div className="text-center my-3">
          <QRCodeSVG value={currentUrl} size={150} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={copyLink}>
          Copy Link
        </Button>
        <Button color="success" tag="a" href={whatsappLink} target="_blank">
          Share to WhatsApp
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ShareModal;
