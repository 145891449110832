import {
  getCloudflareUrl,
  getFrameUrl,
} from "./Client/Gallery/cloudflare_image";

const spacingFunction = (gridStyle) => {
  return (width) => {
    if (width < 768) {
      return gridStyle.spacing.laptop * 0.7;
    } else {
      return gridStyle.spacing.laptop;
    }
  };
};
const columnFunction = (gridStyle) => (containerWidth) => {
  if (containerWidth < 400) return gridStyle.columns.laptop - 2;
  if (containerWidth < 800) return gridStyle.columns.laptop - 1;
  return gridStyle.columns.laptop;
};

const targetRowHeightFunction = (gridStyle) => (containerWidth) => {
  if (containerWidth < 400) return gridStyle.targetRowHeight.laptop * 0.6;
  if (containerWidth < 800) return gridStyle.targetRowHeight.laptop * 0.9;
  return gridStyle.targetRowHeight.laptop;
};

const createGridFunctions = (gridStyle) => {
  const props = {};
  if (gridStyle.spacing) {
    props.spacing = spacingFunction(gridStyle);
  }
  if (gridStyle.columns) {
    props.columns = columnFunction(gridStyle);
  }
  if (gridStyle.targetRowHeight) {
    props.targetRowHeight = targetRowHeightFunction(gridStyle);
  }
  return props;
};

const addFrame = () => {};

const VERSIONS = ["thumbnail", "med", "high", "img"];
const getVersionHeight = (version, originalHeight) => {
  let height;
  switch (version) {
    case "thumbnail":
      height = originalHeight > 480 ? 480 : originalHeight;
      break;
    case "med":
      height = originalHeight > 1080 ? 1080 : originalHeight;
      break;
    case "high":
      height = originalHeight > 2000 ? 2000 : originalHeight;
      break;
    default:
      height = originalHeight;
      break;
  }
  return height;
};
const getImageVersions = (imageData, branding, frame, fullSizeFrame) => {
  const originalHeight = Number(imageData.height);
  const originalWidth = Number(imageData.width);
  const ratio = originalWidth / originalHeight;

  const imageVersions = {};
  VERSIONS.forEach((versionName) => {
    const src = imageData.path_dict[versionName + "_url"];
    if (src) {
      let versionData = {};
      var height = getVersionHeight(versionName, originalHeight);
      const width = height * ratio;
      versionData = { src, height, width };

      let cfSrc;
      if (!src.includes("cdn-cgi")) {
        cfSrc = getCloudflareUrl({
          img_url: src,
          originalHeight: height,
          originalWidth: width,
          branding,
          frame,
        });
      } else {
        cfSrc = src;
      }
      const cfData = { src: cfSrc, height, width };
      versionData = { ...versionData, cf: cfData };

      if (fullSizeFrame) {
        let fullSizeFrameData;
        if (!src.includes("cdn-cgi")) {
          fullSizeFrameData = { src, height, width };
        } else {
          fullSizeFrameData = getFrameUrl({
            img_url: src,
            originalHeight: height,
            originalWidth: width,
            frame,
          });
        }
        versionData = { ...versionData, fullSizeFrameData };
      }

      imageVersions[versionName] = versionData;
    }
  });
  return imageVersions;
};

const getActivityKey = (
  type,
  eventId,
  image_id,
  requestId,
  sponsor_id,
  player_id
) => {
  let unique_key = `visit_${type}_${eventId}`;
  if (image_id) {
    unique_key += `_${image_id}`;
  }
  if (requestId) {
    unique_key += `_${requestId}`;
  }
  if (sponsor_id) {
    unique_key += `_${sponsor_id}`;
  }
  if (player_id) {
    unique_key += `_${player_id}`;
  }
  return unique_key;
};

export { createGridFunctions, getImageVersions, getActivityKey };
