import Loader from "components/Loader";
import React from "react";

const SponsorLogo = (sponsorIq) => {
  const image = sponsorIq?.sponsorIq?.[1]?.image_url;
  const text = sponsorIq?.sponsorIq?.[0]?.text;

  return (
    <div className="d-flex justify-content-center mt-2">
      <div
        style={{ maxWidth: "350px", maxHeight: "350px", overflow: "hidden" }}
        className="text-center"
      >
        {image ? (
          <>
            <img
              // style={{ height: "90%" }}
              className="object-fit-contain rounded-3 w-50 w-md-100 rounded-circle"
              src={image}
              alt={text}
            ></img>
            <h5 className="mt-2 text-primary">{text}</h5>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default SponsorLogo;
