import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";

// Import Routes all
import { openRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";
import createComponents from "helpers/createComponents";
import lz from "lzutf8";
import PortfolioLayout from "components/Portfolio/Layout";
import PortfolioGalleryPage from "pages/Portfolio/PortfolioGalleryPage";
import useSWR, { SWRConfig } from "swr";
import { GET_OPEN_PORTFOLIO } from "helpers/jiraya_url";
import { getOpenPortfolio } from "helpers/jiraya_helper";
import { GlobalProvider } from "store/event/globalContext";
import PublicGallery from "pages/Public/PublicGallery";
import Upload from "components/Events/Client/Upload/upload_image_v1";
import { User } from "pages/Authentication/Login";
import { fetchMessaging } from "store/guest_user/helper";

// Import Firebase Configuration file
// import Upload2 from "components/Events/Forms/upload_image2";

// Activating fake backend
// fakeBackend()

//init firebase backend

const App = (props) => {
  const [globalStates, setGlobalStates] = useState({});
  // const [pageData, setPageData] = useState();
  useEffect(() => {
    const host = new URL(window.location).host;
    const queryParams = new URLSearchParams(window.location.search);
    const debug_domain_pattern = queryParams.get("domain_pattern");

    const domain_pattern = host.includes("localhost")
      ? debug_domain_pattern
      : host;
    if (!domain_pattern) {
      console.log("add domain_pattern query params for localhost");
    }
    setGlobalStates({ domain_pattern });
  }, []);

  // const { data, error } = useSWR(
  //   GET_OPEN_PORTFOLIO ? globalStates.domain_pattern : null,
  //   () =>
  //     getOpenPortfolio({
  //       params: { domain_pattern: globalStates.domain_pattern },
  //     })
  // );
  // const loading = !data;

  // useEffect(() => {
  //   if (!loading) {
  //     if (data.ok) {
  //       const structure = data.data.portfolio_structure;
  //       // const localData = localStorage.getItem("client-portfolio") || defaultRaw;
  //       setPageData(JSON.parse(lz.decompress(lz.decodeBase64(structure))));
  //     }
  //   }
  // }, [loading]);

  // useEffect(() => {
  //   // const data = JSON.parse(localStorage.getItem("client-website"));
  //   const localData = localStorage.getItem("client-portfolio") || defaultRaw;
  //   const rawData = JSON.parse(lz.decompress(lz.decodeBase64(localData)));
  //   const _pageData = rawData
  //     ? Object.entries(rawData).map(([pageId, page]) => ({
  //         id: pageId,
  //         data: JSON.parse(page),
  //         // data: page,
  //       }))
  //     : [];
  //   setPageData(_pageData);
  // }, []);

  // This ensures that the service worker is registered before the the topken is fetched from firebase, to there will be non error in subscribing tot he service worker
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register(
          "/firebase-messaging-sw.js?env=" +
            encodeURIComponent(JSON.stringify(process.env)),
          {
            scope: "/firebase-cloud-messaging-push-scope",
          }
        )
        .then((registration) => {
          console.info(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch((err) => {
          console.error("Service Worker registration failed:", err);
        });
    }
  });

  return (
    <React.Fragment>
      <ToastContainer
        autoClose={2000}
        position="bottom-right"
        hideProgressBar={true}
        className={"custom-toast"}
        theme={"dark"}
        draggable
        pauseOnHover
        limit={1}
      />
      <User>
        <Upload>
          <GlobalProvider value={globalStates}>
            <Router>
              <Switch>
                {/* <PortfolioLayout pages={pageData} brand={data.data.branding}> */}
                {/* {
              <Route path={"/portfolio/gallery/:eventId"}>
                <PortfolioGalleryPage />
              </Route>
            } */}
                {/* {
              <Route path={"/Public/PublicGallery"}>
                <PublicGallery />
              </Route>
            } */}

                {/* <User>
                {authRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    key={idx}
                    component={route.component}
                    isAuthProtected={true}
                  />
                ))}
              </User> */}
                {openRoutes.map((route, idx) => (
                  <Route
                    path={route.path}
                    key={idx}
                    component={route.component}
                  />
                ))}

                {/* </PortfolioLayout> */}

                {/* {pageData &&
              pageData.map((page) => (
                <Route path={`/${page.id}`} key={page.id}>
                  <PortfolioLayout pages={pageData} brand={data.data.branding}>
                    {createComponents(page.id, page.data)}
                  </PortfolioLayout>
                </Route>
              ))} */}

                <Route
                  path={"/"}
                  exact={true}
                  component={() => <Redirect to="/home" />}
                />
              </Switch>
            </Router>
          </GlobalProvider>
        </Upload>
      </User>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

export default App;
