import { clsx } from "yet-another-react-lightbox";

const PictureCard = ({
  img,
  name,
  id,
  btnText,
  onClick,
  variant = "md",
  postTitle,
  ...rest
}) => {
  return (
    <div
      className={clsx(
        variant === "md" ? "find-my-pic-with-card" : "find-my-pic-with-card-lg"
      )}
      title={name}
      id={id}
      {...rest}
    >
      <img
        src={img}
        className="img-fluid rounded-circle"
        style={{ aspectRatio: "1", objectFit: "cover", cursor: "pointer" }}
        alt={name}
        onClick={onClick}
      />
      <small
        className="text-center d-block lh-sm mt-1"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        {postTitle}
        <br />
        {name}
      </small>
      <button
        style={{ backgroundColor: "#2f3471", color: "#fff" }}
        className="btn btn-sm btn-soft-primary text-center w-100 mt-2 py-1 text-truncate"
        onClick={onClick}
      >
        {btnText || "Get Photos Now"}
      </button>
    </div>
  );
};

export default PictureCard;
