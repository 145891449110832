import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle } from "reactstrap";

const TeamCard = ({ image, name, description }) => {
  return (
    <motion.div
      whileHover={{
        scale: 1.05,
        transition: { duration: 0.2 },
      }}
      className="h-100"
    >
      <Card
        className="h-100 shadow "
        style={{
          backgroundColor: "var(--gallery-background-color-2)",
          border: "2px solid var(--gallery-primary-color)",
          borderRadius: "10px",
        }}
      >
        <motion.img
          whileHover={{ scale: 1.1 }}
          src={image}
          alt={name}
          className="card-img-top"
          style={{
            height: "200px",
            objectFit: "contain",
            width: "100%",
          }}
        />
        <CardBody>
          <CardTitle tag="h5" className="mb-1 text-center">
            {name}
          </CardTitle>
          {/* <CardText className="text-muted">{description}</CardText> */}
        </CardBody>
      </Card>
    </motion.div>
  );
};

TeamCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default TeamCard;
