import {
  getEventImages,
  getOpenEventImages,
  postOpenActivity,
} from "helpers/jiraya_helper";
import { GET_EVENT_IMAGES, GET_EVENT_IMAGES_OPEN } from "helpers/jiraya_url";

import { dateToString } from "helpers/utils";
import ClientGallery from "./Gallery/gallery4";
import { createRef, useEffect, useMemo, useState } from "react";

import CoverTheme from "./CoverThemes/factory";
import EventAccess from "./AccessForms/EventAccess";
import PreRegistration from "./PreRegistration/basic";
import {
  GALLERY_ACCESS_LEVEL,
  GALLERY_MODE,
  GALLERY_PREVIEW_MODE,
  ACTIVITY,
} from "./enums";
import FaceOnlyAccess from "./AccessForms/FaceOnlyForm";
import WebFont from "webfontloader";
import swal from "sweetalert";
import { GalleryProvider } from "store/galleryContext";
import { COLLECTION_TYPE } from "../enums";
import useTracks from "../HashTrack/factory";
import Footer from "./footer";
import { useTranslation } from "react-i18next";

import GuestUploadPage from "pages/Public/GuestUploadPage";
import { Modal } from "reactstrap";
import ListAdvocacyPosterPage from "./Spotlight/ListAdvocacyPosterPage";
import SanyRegisterationForm from "./PreRegistration/SanyRegistrationForm";
import { fetchToken } from "store/guest_user/helper";
import { postFCMToken } from "helpers/jiraya_helper";
import Teams from "components/Teams";
import { getActivityKey } from "../utils";
import SponsorLogo from "./Gallery/sponsorLogo";
import { SuccessPopup } from "components/Notification/GeneralPopups";

const isDashboard = process.env.REACT_APP_APPTYPE === "dashboard";
var getImagesFunc, GET_IMAGES_URL;
if (isDashboard) {
  getImagesFunc = getEventImages;
  GET_IMAGES_URL = GET_EVENT_IMAGES;
} else {
  getImagesFunc = getOpenEventImages;
  GET_IMAGES_URL = GET_EVENT_IMAGES_OPEN;
}

const EventGallery = (props) => {
  const {
    eventId,
    eventData,
    collectionData,
    collectionPosition,
    galleryProps,
    gridStyle,
    coverTheme,
    coverTextStyle,
    colorPaletteStyle,
    brandingStyle,
    coverPosition,
    galleryAccessControl,
    handleOnValidPin,
    deviceId,
    albumSelection,
    handleChangeAlbumSelection,
    themeType,
    trackActivity,
    galleryExpired,
    isPortfolio,
    access_key,
    teamView,
    orgId,
    filterText,
    handleOnChangeEventId,
    showAdvocacyPoster,
    showOnlyGuestUpload,
    isOperatorRegistrationForm,
    showTeam,
    playerGalleryProps,
    sponsorIq,
    queryCollectionId,
    queryCollectionKey,
  } = props;
  useEffect(() => {
    document.body.setAttribute("gallery-mode", colorPaletteStyle["color-mode"]);

    const body = document.querySelector("body");
    Object.entries(colorPaletteStyle).map(([colorName, colorValue]) => {
      body.style.setProperty(`--gallery-${colorName}`, colorValue);
    });

    return () => {
      document.body.setAttribute("data-layout-mode", "light");
    };
  }, [colorPaletteStyle]);

  useEffect(() => {
    const body = document.body;
    body.style.setProperty(
      "--gallery-side-padding",
      `${galleryProps.sidePadding}%`
    );
    body.style.setProperty(
      "--gallery-image-radius",
      `${galleryProps.radius}px`
    );
  }, [galleryProps]);

  useEffect(() => {
    const body = document.body;
    const cover = brandingStyle.cover;
    body.style.setProperty("--cover-logo-size", `${cover.logoSize}vh`);

    body.style.setProperty(
      "--show-cover-inquiry",
      brandingStyle.cover.showInquiry ? "flex" : "none"
    );

    const footer = brandingStyle.footer;
    body.style.setProperty("--footer-logo-size", `${footer.logoSize}vh`);

    body.style.setProperty("--cover-logo-size", `${cover.logoSize}vh`);
  }, [brandingStyle]);

  // typography
  useEffect(() => {
    const body = document.body;
    body.style.setProperty("--gallery-cover-event-font", coverTextStyle.font);
    const { "cover-event-font": coverEventFont } = coverTextStyle;

    WebFont.load({
      google: {
        families: [coverEventFont],
      },
    });
    Object.entries(coverTextStyle).map(([name, value]) => {
      body.style.setProperty(`--gallery-${name}`, value);
    });
  }, [coverTextStyle]);

  const galleryRef = createRef();
  const handleOnViewGallery = () => {
    galleryRef.current.scrollIntoView();
  };

  const [showGalleryComponents, setShowGalleryComponents] = useState({
    showGallery: false,
    showRegistrationForm: false,
    showPINModal: false,
    showFaceSearchModal: false,
  });
  const [galleryMode, setGalleryMode] = useState(
    eventData.publish_status === 0 || eventData.publish_status === 1
      ? GALLERY_MODE.REGISTRATION
      : GALLERY_MODE.GALLERY
  );
  useEffect(() => {
    setGalleryMode(
      eventData.publish_status === 0 || eventData.publish_status === 1
        ? GALLERY_MODE.REGISTRATION
        : GALLERY_MODE.GALLERY
    );
  }, [eventData]);

  const { sortedCollectionData, activeCollection } = useMemo(() => {
    if (collectionPosition && collectionData) {
      const _sortedCollections = [];
      const parseCollectionData = [...collectionData];
      var activeCollection;
      collectionPosition.forEach((pos) => {
        const index = parseCollectionData.findIndex((col) => col.id === pos.id);
        if (index !== -1) {
          const col = parseCollectionData.splice(index, 1)[0];

          if (pos.isVisible) {
            if (pos.isDefault && !galleryAccessControl.requestId) {
              activeCollection = pos.id;
            } else if (
              col.type === COLLECTION_TYPE.PEOPLE &&
              galleryAccessControl.requestId
            ) {
              activeCollection = col.id;
            }
            _sortedCollections.push(col);
          }
        }
      });

      parseCollectionData.forEach((col) => {
        if (
          galleryAccessControl.requestId &&
          col.type === COLLECTION_TYPE.PEOPLE
        ) {
          activeCollection = col.id;
        }
        _sortedCollections.push(col);
      });
      if (isNaN(activeCollection) && _sortedCollections.length > 0) {
        activeCollection = _sortedCollections[0].id;
      }
      if (albumSelection) {
        activeCollection = -1;
      }
      return { sortedCollectionData: _sortedCollections, activeCollection };
    } else {
      activeCollection = -1;
      if (!galleryAccessControl.requestId && collectionData?.length > 0) {
        activeCollection = collectionData[0].id;
      } else {
        collectionData &&
          collectionData.forEach((col) => {
            if (col.type === COLLECTION_TYPE.PEOPLE) {
              activeCollection = col.id;
            }
          });
      }
      if (albumSelection) {
        activeCollection = -1;
      }
      return {
        sortedCollectionData: collectionData,
        activeCollection: activeCollection,
      };
    }
  }, [eventId, collectionData, collectionPosition, albumSelection]);

  const isCollectionLoading = useMemo(
    () => !sortedCollectionData?.length > 0,
    [sortedCollectionData]
  );

  // activity
  const handleActivity = async (type, image_id, sponsor_id) => {
    const requestId = galleryAccessControl?.requestId;
    if (trackActivity) {
      const unique_key = getActivityKey(
        type,
        eventId,
        image_id,
        requestId,
        sponsor_id
      );
      const visit = JSON.parse(localStorage.getItem(unique_key));
      if (!visit) {
        localStorage.setItem(unique_key, "true");
        await postOpenActivity({
          event_id: eventId,
          activity_type: type,
          image_id,
          request_id: requestId,
          sponsor_id: sponsor_id,
        });
      }
    }
  };

  // event prompts
  // const {data:promptData} = useSWR([],)
  // const promptData = [
  //   {
  //     id: 1,
  //     type: 1,
  //     data: { header: "hello", body: "use tags", message: "#fotoowl" },
  //     triggerType: 1,
  //   },
  // ];

  const {
    isTrackRegistered,
    setIsTrackRegistered,
    trackRegistry,
    registerTrigger,
    handleTrigger,
  } = useTracks();

  useEffect(() => {
    registerTrigger(eventData.prompt_notification_list);
    setIsTrackRegistered(true);
  }, [eventData]);

  useEffect(() => {
    if (isTrackRegistered) {
      handleTrigger("gallery_visit", {});
    }
  }, [eventId, isTrackRegistered]);

  useEffect(() => {
    let timeoutId;
    if (galleryMode === GALLERY_MODE.GALLERY) {
      timeoutId = setTimeout(() => {
        handleActivity(ACTIVITY.GALLERY_VISIT);
      }, 5000);
    }
    return () => timeoutId && clearTimeout(timeoutId);
  }, [eventData]);

  // access control
  useEffect(() => {
    var showGallery = false;
    var showRegistrationForm = false;
    var showPINModal = false;
    var showFaceSearchModal = false;

    if (galleryAccessControl.mode === GALLERY_PREVIEW_MODE.CLIENT) {
      if (
        galleryAccessControl.pinRequired &&
        !galleryAccessControl.pinValidated
      ) {
        showPINModal = true;
      }
    }
    if (showAdvocacyPoster) {
      showGallery = false;
      showRegistrationForm = false;
      showFaceSearchModal = false;
    } else if (
      galleryAccessControl.mode === GALLERY_PREVIEW_MODE.CLIENT &&
      !isCollectionLoading
    ) {
      if (
        galleryAccessControl.pinRequired &&
        galleryAccessControl.pinValidated
      ) {
        if (galleryMode === GALLERY_MODE.REGISTRATION) {
          showRegistrationForm = true;
        } else {
          if (
            galleryAccessControl.pinAccessLevel === GALLERY_ACCESS_LEVEL.FULL ||
            galleryAccessControl.requestId
          ) {
            var showGallery = true;
          } else {
            showFaceSearchModal = true;
          }
        }
      } else if (
        galleryAccessControl.pinRequired &&
        !galleryAccessControl.pinValidated
      ) {
        showPINModal = true;
      } else {
        if (galleryMode === GALLERY_MODE.REGISTRATION) {
          showRegistrationForm = true;
        } else if (
          galleryAccessControl.pinAccessLevel === GALLERY_ACCESS_LEVEL.FULL ||
          galleryAccessControl.requestId
        ) {
          var showGallery = true;
        } else {
          showFaceSearchModal = true;
        }
      }
    } else if (
      galleryAccessControl.mode === GALLERY_PREVIEW_MODE.GALLERY_PREVIEW &&
      !isCollectionLoading
    ) {
      showGallery = true;
      setGalleryMode(GALLERY_MODE.GALLERY);
    } else if (
      galleryAccessControl.mode === GALLERY_PREVIEW_MODE.REGISTRATION_PREVIEW
    ) {
      showRegistrationForm = true;
      setGalleryMode(GALLERY_MODE.REGISTRATION);
    }
    setOpenFaceSearch(showFaceSearchModal);
    showFaceSearchModal && setAllowToggleFaceSearch(false);
    setShowGalleryComponents({
      showGallery,
      showRegistrationForm,
      showPINModal,
      showFaceSearchModal,
    });
  }, [eventId, isCollectionLoading, galleryAccessControl]);

  const { i18n } = useTranslation();
  const language = useMemo(() => {
    return i18n.language.split("-")[0];
  });

  const eventDetails = useMemo(() => ({
    ...eventData,
    ...{ date: dateToString(eventData.date, language) },
  }));

  const [showAllImages, setShowAllImages] = useState(
    galleryAccessControl?.requestId ? false : true
  );
  const handleToggleShowAllImages = () => {
    setShowAllImages((p) => !p);
  };
  ///////////////////////// Face Search //////////////////////////////////////////
  const [allowToggleFaceSearch, setAllowToggleFaceSearch] = useState(true);
  const [openFaceSearch, setOpenFaceSearch] = useState(false);
  const toggleOpenFaceSearch = () => {
    !isDashboard && setOpenFaceSearch(!openFaceSearch);
  };
  const handleOnOpenFaceSearch = () => {
    !isDashboard && setOpenFaceSearch(true);
  };
  const handleOnResponse = async (response) => {
    if (response.ok) {
      swal({
        content: (
          <SuccessPopup
            heading={"You made it! Your Event photos are on the way."}
            body={
              "Click Allow Notifications to get notified as soon as they're ready. Nospams, just memories.✨📸"
            }
          />
        ),
        buttons: {
          confirm: "Allow Notifications",
        },
      }).then(async () => {
        const fcmToken = await fetchToken();
        if (fcmToken) {
          await postFCMToken({
            request_id: response.data.request_id,
            firebase_token: fcmToken,
          }).then((res) => {
            console.info(res);
          });
        }
      });
      const redirectUrl = new URL(response.data.redirect_url);
      const queryParams = new URLSearchParams(window.location.search);
      const isVIPLink = Number(queryParams.get("vip-link"));
      isVIPLink && redirectUrl.searchParams.append("vip-link", isVIPLink);

      window.location = redirectUrl.href;
    } else {
      swal("Face Search failed", "Contact the administrator", "error");
    }
  };

  // guest upload modal
  const isGuestUploadAllowed =
    eventData?.guest_upload_restriction == 1 ? true : false;
  const isAutoApproveUpload = eventData?.guest_upload_auto_approve;
  const [openGuestUpload, setOpenGuestUpload] = useState(false);
  const handleOpenGuestUpload = () => {
    setOpenGuestUpload(true);
  };
  const handleCloseGuestUpload = () => {
    setOpenGuestUpload(false);
  };
  useEffect(() => {
    if (isGuestUploadAllowed && showOnlyGuestUpload) {
      handleOpenGuestUpload();
    }
  }, [isGuestUploadAllowed, showOnlyGuestUpload]);

  // My Uploads
  const [isOpenMyUploads, setIsOpenMyUploads] = useState(false);
  const toggleMyUploads = () => {
    setIsOpenMyUploads((p) => !p);
  };
  const handleOnOpenMyUploads = () => {
    setIsOpenMyUploads(true);
  };

  // Render the main gallery content based on different conditions
  const renderGalleryContent = () => {
    // If gallery is expired, don't render anything
    if (galleryExpired) return null;

    // If PIN is not validated, don't render gallery content
    if (!galleryAccessControl?.pinValidated && !isOperatorRegistrationForm)
      return null;

    const galleryConfig = {
      isPublished: showGalleryComponents.showGallery,
      galleryRef: galleryRef,
      eventId: eventId,
      event: eventData,
      eventKey: galleryAccessControl?.pin,
      eventKeyType: galleryAccessControl?.pinAccessLevel,
      galleryPrivacy: galleryAccessControl?.galleryPrivacy,
      collectionData: sortedCollectionData,
      activeCollection: activeCollection,
      galleryProps: galleryProps,
      fetcher: getImagesFunc,
      fetcherUrl: GET_IMAGES_URL,
      allowLoadMore: !isDashboard,
      orderBy: gridStyle.orderBy?.value,
      orderAsc: gridStyle.orderBy?.asc ?? true,
      albumSelection: albumSelection,
      handleChangeAlbumSelection: handleChangeAlbumSelection,
      request_id: galleryAccessControl.requestId,
      request_key: galleryAccessControl.requestKey,
      onOpenFaceSearch: handleOnOpenFaceSearch,
      showAllImages: showAllImages,
      setShowAllImages: setShowAllImages,
      handleToggleShowAllImages: handleToggleShowAllImages,
      isPortfolio: isPortfolio,
      access_key: access_key,
      teamView: teamView,
      orgId: orgId,
      filterText: filterText,
      handleOnChangeEventId: handleOnChangeEventId,
      isGuestUploadAllowed: isGuestUploadAllowed,
      handleOpenGuestUpload: handleOpenGuestUpload,
      handleOnOpenMyUploads: handleOnOpenMyUploads,
      toggleMyUploads: toggleMyUploads,
      isOpenMyUploads: isOpenMyUploads,
      playerGalleryProps: playerGalleryProps,
      queryCollectionId,
      queryCollectionKey,
    };

    // Operator Registration Form View
    if (isOperatorRegistrationForm) {
      return (
        <SanyRegisterationForm
          galleryRef={galleryRef}
          eventId={eventId}
          eventDetails={eventData}
          passkey={galleryAccessControl.pin}
          accessKey={galleryAccessControl.access_key}
          isOpen={true}
          handleOpenGuestUpload={handleOpenGuestUpload}
          isGuestUploadAllowed={isGuestUploadAllowed}
        />
      );
    }
    // Advocacy Poster View
    if (showAdvocacyPoster) {
      return <ListAdvocacyPosterPage eventId={eventId} />;
    }

    // Team View
    if (showTeam) {
      return <Teams eventId={eventId} {...galleryConfig} />;
    }

    // sponsorIq
    if (sponsorIq) {
      return (
        <>
          <SponsorLogo sponsorIq={sponsorIq} />
          <ClientGallery {...galleryConfig} />
        </>
      );
    }
    return (
      <>
        <ClientGallery {...galleryConfig} />

        {/* Show registration form if needed */}
        {showGalleryComponents.showRegistrationForm && (
          <PreRegistration
            galleryRef={galleryRef}
            eventId={eventId}
            eventDetails={eventData}
            passkey={galleryAccessControl.pin}
            accessKey={galleryAccessControl.access_key}
            isOpen={true}
            handleOpenGuestUpload={handleOpenGuestUpload}
            isGuestUploadAllowed={isGuestUploadAllowed}
          />
        )}
      </>
    );
  };

  return (
    <>
      {/* Guest Upload Modal */}
      {openGuestUpload && (
        <Modal
          centered
          isOpen={openGuestUpload}
          size="lg"
          className="gallery-modal"
          zIndex={1051}
        >
          <GuestUploadPage
            eventId={eventId}
            onCancel={handleCloseGuestUpload}
            onUpload={handleCloseGuestUpload}
            handleOnOpenMyUploads={handleOnOpenMyUploads}
            toggleMyUploads={toggleMyUploads}
            isAutoApproveUpload={isAutoApproveUpload}
          />
        </Modal>
      )}

      <GalleryProvider value={{ handleActivity, handleTrigger }}>
        {/* Activity Tracking Components */}
        {trackRegistry.map((item, key) => {
          const Component = item.Component;
          return (
            <Component
              key={key}
              isOpenDownloadMessage={item.isOpen}
              {...item.data}
              {...item.props}
            />
          );
        })}

        <div>
          <div className="client-gallery-container">
            {/* Cover Theme Section */}
            <CoverTheme
              name={themeType}
              eventId={eventId}
              eventDetails={eventDetails}
              handleOnViewGallery={handleOnViewGallery}
              isCollectionLoading={isCollectionLoading}
              albumSelection={false}
              deviceId={deviceId}
              isFotoOwlBranding={eventData && !eventData.no_foto_owl_branding}
              {...{
                galleryProps,
                coverTheme,
                coverTextStyle,
                colorPaletteStyle,
                brandingStyle,
                coverPosition,
              }}
              galleryMode={galleryMode}
              isGuestUploadAllowed={isGuestUploadAllowed}
              handleOpenGuestUpload={handleOpenGuestUpload}
            >
              {/* PIN Modal */}
              {showGalleryComponents.showPINModal && (
                <EventAccess
                  eventId={eventId}
                  eventDetails={eventData}
                  handleOnValidPin={handleOnValidPin}
                  pin={galleryAccessControl.pin}
                />
              )}

              {/* Face Access Section */}
              {!isOperatorRegistrationForm && (
                <FaceOnlyAccess
                  eventId={eventId}
                  eventDetails={eventData}
                  pin={galleryAccessControl.pin}
                  accessKey={galleryAccessControl.access_key}
                  openFaceSearch={openFaceSearch}
                  handleOnResponse={handleOnResponse}
                  toggleOpenFaceSearch={
                    allowToggleFaceSearch && toggleOpenFaceSearch
                  }
                  isGuestUploadAllowed={isGuestUploadAllowed}
                  handleOpenGuestUpload={handleOpenGuestUpload}
                />
              )}
            </CoverTheme>

            {/* Main Gallery Content */}
            {renderGalleryContent()}
          </div>

          {/* Footer */}
          {eventData && (
            <Footer
              eventId={eventId}
              eventData={eventData}
              brandingStyle={brandingStyle}
            />
          )}
        </div>
      </GalleryProvider>
    </>
  );
};

export default EventGallery;
