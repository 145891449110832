import useCountry from "helpers/data_hooks.js/useCountry";
import { useEffect, useState } from "react";
import { FormGroup, Input, Col, Row, Label } from "reactstrap";

const machine_data = {
  Excavator: {
    "Mini Excavator": ["SY35 pro", "SY60 C", "SY80C-9 PRO"],
    "Small Excavator": ["SY80 pro", "SY150 pro"],
    "Medium Excavator": ["SY210 SPARC +", "SY215c-9 lc X", "SY225 GENe +"],
    "Motor Grader": ["STG140 BS5/STG170BS5"],
  },
  Piling: {
    "Piling Rig": ["SR235DW", "SR140", "SH500/SH700", "SR285R/SR360R"],
  },
  Asphalt: {
    "Batching Plant": ["SABP180"],
    Paver: ["SAP100C10 BSV"],
    "Milling Machine": ["SCM 2000"],
    Compactor: ["SSR20"],
  },
  Mining: { "Large Excavator": ["SY680"], "Mining Truck": ["SKT 105E"] },
  Crane: {
    Articulated: ["SPA 20 meters"],
    Telescopic: ["SPT 26 / 32 meters"],
    "Scissor lift": ["SPS 1212"],
    "Truck Crane": ["STC600T5P (60 T Hybrid)"],
    "All Terrain Crane": ["SAC3000"],
    "Crawler Crane": ["SCS1500A Luffing"],
    "Rough Terrain Crane": ["SRC400CR"],
  },
  Port: {
    "Reach stacker": ["SRSC45E3 Electric"],
    "E-ITV Terminal Tractor": ["SM4256T0BEV"],
  },
  Robotics: { Forklift: ["SCP30C6"] },
  Road: {
    "Tracked Paver": ["SAP80C-10"],
    "Tracked Milling Machine": ["SCM1300FC"],
  },
  Other: { Other: ["Other"] },
};

const dealer_list = [
  { name: "ACE Infraline (Mumbai)", value: "ACE Infraline (Mumbai)" },
  { name: "ACE Infraline (Nashik)", value: "ACE Infraline (Nashik)" },
  { name: "ACE Infraline (Navi-Mumbai)", value: "ACE Infraline (Navi-Mumbai)" },
  { name: "Avni Automobiles (Bhopal)", value: "Avni Automobiles (Bhopal)" },
  { name: "Avni Automobiles (Indore)", value: "Avni Automobiles (Indore)" },
  { name: "Bhajuratna Agency Pvt. Ltd.", value: "Bhajuratna Agency Pvt. Ltd." },
  { name: "BVS Equipments", value: "BVS Equipments" },
  { name: "DAS Construction", value: "DAS Construction" },
  {
    name: "Global Trading Corporation, Haryana",
    value: "Global Trading Corporation, Haryana",
  },
  { name: "Icones Equipments", value: "Icones Equipments" },
  { name: "Ilaa Maldives Pvt. Ltd.", value: "Ilaa Maldives Pvt. Ltd." },
  { name: "Jasraj Infra", value: "Jasraj Infra" },
  { name: "Jove Motors", value: "Jove Motors" },
  { name: "K Associates", value: "K Associates" },
  {
    name: "L & I Construction Equipment",
    value: "L & I Construction Equipment",
  },
  { name: "M Vignesh Enterprises", value: "M Vignesh Enterprises" },
  {
    name: "Madhura Engineering Services Pvt. Ltd., Hyderabad",
    value: "Madhura Engineering Services Pvt. Ltd., Hyderabad",
  },
  {
    name: "Madhura Engineering Services Pvt. Ltd., Rayalasema",
    value: "Madhura Engineering Services Pvt. Ltd., Rayalasema",
  },
  {
    name: "Makroo Motor Company, Srinagar",
    value: "Makroo Motor Company, Srinagar",
  },
  {
    name: "Masany Construction Equipment Pvt. Ltd.",
    value: "Masany Construction Equipment Pvt. Ltd.",
  },
  { name: "Masany Infra Equipments LLP", value: "Masany Infra Equipments LLP" },
  { name: "Newage Techsol (Coastal)", value: "Newage Techsol (Coastal)" },
  {
    name: "Nilachal Greentech Engineers Pvt. Ltd.",
    value: "Nilachal Greentech Engineers Pvt. Ltd.",
  },
  { name: "Om Hydraulics Equipments", value: "Om Hydraulics Equipments" },
  { name: "Om Infracore", value: "Om Infracore" },
  {
    name: "Opender Kanwaljit Constructive Solutions LLP.",
    value: "Opender Kanwaljit Constructive Solutions LLP.",
  },
  {
    name: "Pawansut Infra Developers Pvt. Ltd.",
    value: "Pawansut Infra Developers Pvt. Ltd.",
  },
  { name: "Powervision Limited", value: "Powervision Limited" },
  {
    name: "Raagni Equipment Pvt. Ltd., Eastern UP",
    value: "Raagni Equipment Pvt. Ltd., Eastern UP",
  },
  {
    name: "Raagni Equipment Pvt. Ltd., Uttarakhand",
    value: "Raagni Equipment Pvt. Ltd., Uttarakhand",
  },
  { name: "Raghunath Machinery", value: "Raghunath Machinery" },
  { name: "Risheek Automotive", value: "Risheek Automotive" },
  { name: "RS Infra Equipments", value: "RS Infra Equipments" },
  { name: "Santhosh Motorss", value: "Santhosh Motorss" },
  { name: "Sany Delhi", value: "Sany Delhi" },
  { name: "Sany Delhi (Jammu)", value: "Sany Delhi (Jammu)" },
  { name: "Sany Delhi (Western UP)", value: "Sany Delhi (Western UP)" },
  { name: "Sany North Karnataka", value: "Sany North Karnataka" },
  { name: "Sany Trichy", value: "Sany Trichy" },
  { name: "Sany West Bengal", value: "Sany West Bengal" },
  {
    name: "Shree Balajee Infraequipment Pvt. Ltd., Jaipur",
    value: "Shree Balajee Infraequipment Pvt. Ltd., Jaipur",
  },
  {
    name: "Shree Balajee Infraequipment Pvt. Ltd., Udaipur",
    value: "Shree Balajee Infraequipment Pvt. Ltd., Udaipur",
  },
  {
    name: "Shree Lakshmi Infra Equipments",
    value: "Shree Lakshmi Infra Equipments",
  },
  { name: "SRBH Infra Solutions", value: "SRBH Infra Solutions" },
  {
    name: "Sunlanka Auto Traders Pvt. Ltd.",
    value: "Sunlanka Auto Traders Pvt. Ltd.",
  },
  { name: "Swasthika Equipments", value: "Swasthika Equipments" },
  { name: "Yoebar Pvt. Ltd.", value: "Yoebar Pvt. Ltd." },
];

const SanyForm = ({ handleChange, handleRegisterName, additionalValues }) => {
  const [machineList, setMachineList] = useState([]);
  const [machineData, setMachineData] = useState([
    {
      Component: MachineField,
      props: { category: "", subcategory: "", modelcode: "", quantity: 0 },
    },
  ]);

  useEffect(() => {
    handleRegisterName("machine_quantity_list", false, "");
    handleRegisterName("city", false, "");
    handleRegisterName("company_name", false, "");
    handleRegisterName("relationship_with_sany", false, "");
    handleRegisterName("dealer_name", false, "");
  }, []);

  const countryData = useCountry();
  useEffect(() => {
    if (countryData) {
      handleChange("city", countryData.city);
    }
  }, [countryData]);

  const handleOnChangeFormValue = (machineData) => {
    const machineList = [];
    machineData.forEach((data) => {
      machineList.push(data.props);
    });
    handleChange("machine_quantity_list", machineList);
  };
  const handleOnChange = (idx, name, value) => {
    machineData[idx].props[name] = value;
    setMachineData([...machineData]);
    handleOnChangeFormValue([...machineData]);
  };

  const handleOnAddMachineGroup = () => {
    const newData = [
      ...machineData,
      {
        Component: MachineField,
        props: { category: "", subcategory: "", modelcode: "", quantity: 0 },
      },
    ];
    setMachineData(newData);
    handleOnChangeFormValue(newData);
  };
  const handleOnRemoveMachineGroup = (idx) => {
    machineData.splice(idx, 1);
    setMachineData([...machineData]);
  };

  return (
    <div className="">
      <FormGroup className="mb-3">
        <Label>Company Name </Label>
        <Input
          value={additionalValues["company_name"]?.value || ""}
          name={"company_name"}
          placeholder={"Company Name"}
          type="text"
          onChange={(e) => handleChange("company_name", e.target.value)}
          // errorMessage={errorMessage}
          className={`form-control`}
          // validate={{ required: { value: errorMessage } }}
          id={"company_name"}
          invalid={additionalValues["company_name"]?.isError}
        />
      </FormGroup>

      <FormGroup className="mb-3">
        <Label>City</Label>
        <Input
          value={additionalValues["city"]?.value || ""}
          name={"city"}
          placeholder={"City"}
          type="text"
          onChange={(e) => handleChange("city", e.target.value)}
          className={`form-control`}
          id={"city"}
          invalid={additionalValues["city"]?.isError}
        />
      </FormGroup>

      <FormGroup>
        <Label>Relationship With Sany</Label>
        <Input
          type="select"
          name="relationship_with_sany"
          id="relationship_with_sany"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          <option disabled selected value>
            ---select---
          </option>
          <option value={"new_customer"}>New Customer</option>
          <option value={"old_customer"}>Old Customer</option>
        </Input>
      </FormGroup>
      <FormGroup className="mt-3">
        <Label>Dealer Name</Label>
        <Input
          type="select"
          name="dealer_name"
          id="dealer_name"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          <option disabled selected value>
            ---select---
          </option>

          {dealer_list.map((item) => (
            <option value={item.value}>{item.name}</option>
          ))}
        </Input>
      </FormGroup>

      {machineData.map((data, idx) => {
        const { Component, props } = data;
        return (
          <Component
            idx={idx}
            key={idx}
            {...props}
            handleOnChange={handleOnChange}
            handleOnRemoveMachineGroup={handleOnRemoveMachineGroup}
          />
        );
      })}
      <div className="d-flex justify-content-end mb-2 mt-3">
        <button
          type="button"
          onClick={handleOnAddMachineGroup}
          className="btn btn-gallery-outline-primary px-4"
        >
          <i className="mdi mdi-plus-circle-outline" /> Add
        </button>
      </div>
    </div>
  );
};

const MachineField = ({ idx, handleOnChange, handleOnRemoveMachineGroup }) => {
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [isCustomField, setIsCustomField] = useState(false);

  const handleOnChangeValue = (name, value) => {
    console.log(name);
    if (name === "category") {
      if (value === "Other") {
        setIsCustomField(true);
        handleOnChange(idx, "subcategory", "");
      } else {
        setIsCustomField(false);
        setSubcategoryList(machine_data[value]);
      }
    } else if (name == "subcategory") {
      setModelList(subcategoryList[value]);
    }
    handleOnChange(idx, name, value);
  };
  const handleOnChangeOther = (value) => {
    handleOnChange(idx, "modelcode", value);
  };

  return (
    <div className="border border-primary border-opacity-50 rounded py-4 px-2 mt-4 position-relative">
      <div
        className="position-absolute top-0 end-0 me-1"
        onClick={() => handleOnRemoveMachineGroup(idx)}
      >
        <i className="mdi mdi-close-circle fs-2" />
      </div>
      <FormGroup className="mb-3 ">
        Category
        <Input
          type="select"
          name={"category"}
          id={"category"}
          // invalid={additionalValues[name]?.isError}
          onChange={(e) => handleOnChangeValue("category", e.target.value)}
          // className={``}
        >
          <option disabled selected value>
            select category
          </option>
          {Object.keys(machine_data).map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </Input>
        {/* <FormFeedback>{errorMessage}</FormFeedback> */}
      </FormGroup>
      {isCustomField ? (
        <FormGroup>
          <Input
            name={"modelcode"}
            id={"modelcode"}
            onChange={(e) => handleOnChangeOther(e.target.value)}
          />
        </FormGroup>
      ) : (
        <FormGroup className="mb-3">
          Sub-Category
          <Input
            type="select"
            name={"subcategory"}
            id={"subcategory"}
            // invalid={additionalValues[name]?.isError}
            onChange={(e) => handleOnChangeValue("subcategory", e.target.value)}
          >
            <option disabled selected value>
              select subcategory
            </option>
            {Object.keys(subcategoryList).map((subcategory) => (
              <option key={subcategory} value={subcategory}>
                {subcategory}
              </option>
            ))}
          </Input>
        </FormGroup>
      )}
      <Row>
        {!isCustomField && (
          <Col>
            <FormGroup className="mb-3">
              Model
              <Input
                type="select"
                name={"modelcode"}
                id={"modelcode"}
                // invalid={additionalValues[name]?.isError}
                onChange={(e) =>
                  handleOnChangeValue("modelcode", e.target.value)
                }
              >
                <option disabled selected value>
                  select model
                </option>
                {modelList.map((model) => (
                  <option key={model} value={model}>
                    {model}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        )}
        <Col>
          <FormGroup className="mb-3">
            Quantity
            <Input
              name={"quantity"}
              placeholder={"Quantity"}
              type="number"
              onChange={(e) => handleOnChangeValue("quantity", e.target.value)}
              className={`form-control `}
              id={"quantity"}
              // invalid={additionalValues[name]?.isError}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default SanyForm;
