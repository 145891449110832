import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { Card } from "reactstrap";

const TeamPlayerCard = ({ image, name, description }) => {
  return (
    <motion.div
      whileHover={{ y: -5 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.2 }}
    >
      <div className="d-flex justify-content-center align-items-center flex-column">
        {/* <Card className="border-0 text-center p-3"> */}
        <motion.div whileHover={{ rotate: 5 }} className="mx-auto mb-3">
          <motion.img
            src={image}
            alt={name}
            className="rounded-circle"
            style={{
              width: "120px",
              height: "120px",
              objectFit: "cover",
              objectPosition: "top",
              border: "3px solid var(--gallery-primary-color)",
            }}
          />
        </motion.div>

        <motion.h5 className="mb-2 text-center" whileHover={{ scale: 1.1 }}>
          {name}
        </motion.h5>

        <motion.p
          className="text-muted mb-0"
          initial={{ opacity: 0.8 }}
          whileHover={{ opacity: 1 }}
        >
          {description}
        </motion.p>
        {/* </Card> */}
      </div>
    </motion.div>
  );
};

TeamPlayerCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default TeamPlayerCard;
