import { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Input,
  FormGroup,
} from "reactstrap";
import FaceSearchForm from "../Forms/face_search";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import getTitleCustomisationData from "./title_customisation";
import UploadNotifyModal from "../Upload/UploadNotifyModal";

const SanyRegisterationForm = ({
  galleryRef,
  eventDetails,
  passkey,
  accessKey,
  eventId,
  isGuestUploadAllowed,
  handleOpenGuestUpload,
}) => {
  const { t } = useTranslation();
  const [registered, setRegistered] = useState(false);
  useEffect(() => {
    const alreadyRegisterd = localStorage.getItem(
      `event-registration:${eventId}`
    );
    if (alreadyRegisterd) {
      setRegistered(true);
      swal(
        t("You are Already Registed"),
        t("You will recieve an Email / WhatsApp as soon as the event is live"),
        "success"
      );
    }
  }, []);
  const handleOnResponse = async (response) => {
    console.log(response);
    if (response.ok) {
      setRegistered(true);
      localStorage.setItem(`event-registration:${eventId}`, true);
      // localStorage.setItem(`event-registration-data:${eventId}`, );
      swal(
        t("Registration Success"),
        t("You will recieve an Email / WhatsApp as soon as the event is live"),
        "success"
      );
    } else {
      swal(t("Registration Failed"), t("Contact the administrator"), "error");
    }
  };

  const { title, subtitle } = useMemo(
    () => getTitleCustomisationData(eventId),
    [eventId]
  );

  const [operatorName, setOperatorName] = useState("");
  const [isOpenOperatorNameModal, setIsOpenOperatorNameModal] = useState(false);
  useEffect(() => {
    let operatorName = localStorage.getItem("sany-operator-name");
    if (!operatorName) {
      setIsOpenOperatorNameModal(true);
    } else {
      setOperatorName(operatorName);
    }
  }, []);
  const handleOnChangeOperatorName = (e) => {
    setOperatorName(e.target.value);
  };
  const handleOnSubmitOperatorName = () => {
    setIsOpenOperatorNameModal(false);
    localStorage.setItem("sany-operator-name", operatorName);
  };
  const handleOnRemoveOperatorName = () => {
    setOperatorName("");
    localStorage.removeItem("sany-operator-name");
    setIsOpenOperatorNameModal(true);
  };

  return (
    <div className="container mt-5" ref={galleryRef}>
      <Modal
        className="gallery-modal"
        isOpen={isOpenOperatorNameModal}
        centered
        size="md"
      >
        <ModalHeader>Enter Operator Details</ModalHeader>
        <ModalBody>
          <FormGroup className="mb-3">
            <Label>Operator Name* </Label>
            <Input
              value={operatorName}
              name={"operator_name"}
              placeholder={"Enter Your Name"}
              type="text"
              onChange={handleOnChangeOperatorName}
              className={`form-control`}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <div>
            <button
              onClick={handleOnSubmitOperatorName}
              className="btn btn-gallery-primary px-3"
            >
              Submit
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <Row className="justify-content-center gx-0">
        <Col md={6} sm={11}>
          {!registered ? (
            <div>
              <h2 className="text-center w-100">
                <i className="mdi mdi-check-circle text-success me-1" />{" "}
                {t("Register to Get Your Photos on your Mobile")}
              </h2>
              <Card className="pre-registration main-card  mt-4">
                <CardHeader className="position-relative">
                  {/* <div className="header-img">
                  <img src={headerBg} style={{ objectFit: "cover" }} />
                </div> */}

                  <div className="header">
                    <h4>{t(title)}</h4>
                    <div style={{ fontWeight: 300, fontSize: "1rem" }}>
                      <small>{t(subtitle)}</small>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="mb-4">
                    <div>
                      Form Filled by: <strong> {operatorName}</strong>
                    </div>
                    <div className="mt-2">
                      <button
                        onClick={handleOnRemoveOperatorName}
                        className="btn btn-gallery-outline-primary"
                      >
                        Change Operator
                      </button>
                    </div>
                  </div>
                  <div className="divider" />
                  <div className="">
                    <FaceSearchForm
                      passkey={passkey}
                      accessKey={accessKey}
                      eventId={eventId}
                      submitButton={t("Register")}
                      onResponse={handleOnResponse}
                      isPreRegistration={true}
                      eventData={eventDetails}
                      isGuestUploadAllowed={isGuestUploadAllowed}
                      handleOpenGuestUpload={handleOpenGuestUpload}
                      isSanyForm={true}
                      operatorName={operatorName}
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
          ) : (
            <Card className="pre-registration main-card">
              <CardHeader>
                <div className="text-center">
                  <h5>{t("Relax and Enjoy the Event")}</h5>
                  <div>
                    {t("We will notify you when your photos are ready")}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <lottie-player
                  src="https://lottie.host/38c2bc23-0dca-4c23-8302-7c2679fca3b7/VuR2lcXuZ2.json"
                  background="transparent"
                  speed="1"
                  style={{ width: "auto", height: "400px" }}
                  className="animation"
                  rendererSettings={{ viewBoxSize: "500 0 2400 590" }}
                  loop
                  autoplay
                ></lottie-player>
                <div
                  onClick={() => setRegistered(false)}
                  className="text-center mt-2"
                  style={{ cursor: "pointer" }}
                >
                  {t("Click here to Register Again")}
                </div>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SanyRegisterationForm;
