import ListTeam from "components/Teams/list_team/list_team";
import TeamPlayer from "components/Teams/player/team_player";
import TeamPlayersList from "components/Teams/player/team_player_list";

const teamRoutes = [
  { path: "/teams/:eventId/:teamId/:playerId", component: TeamPlayer },
  {
    path: "/:domainPattern/teams/:eventId/:teamId/:playerId",
    component: TeamPlayer,
  },
  {
    path: "/:domainPattern/teams/:eventId/:teamId",
    component: TeamPlayersList,
  },
  {
    path: "/teams/:eventId/:teamId",
    component: TeamPlayersList,
  },

  {
    path: "/teams/:eventId",
    component: ListTeam,
  },
  {
    path: "/:domainPattern/teams/:eventId",
    component: ListTeam,
  },
];

export default teamRoutes;
